import * as React from 'react'

import { cn } from '~/utils/index.ts'

type Props = {
	size?: 'medium' | 'large'
	color?: 'light' | 'dark' | 'key'
} & React.ComponentPropsWithoutRef<'button'>

export function Button({
	size = 'medium',
	color = 'dark',
	className,
	...props
}: Props) {
	return (
		<button
			className={cn(
				'border border-current font-light transition duration-300 hover:scale-110 focus:scale-110',
				{
					'px-6 py-2 text-xl': size === 'medium',
					'px-12 py-4 text-heading2': size === 'large',
					'text-black': color === 'dark',
					'text-white': color === 'light',
					'text-key': color === 'key',
				},
				className,
			)}
			{...props}
		/>
	)
}
